const colors = {
  stroke: "#352D2D",
  white: "#FFFFFF",
  black: "#000000",
  primary: {
    1: "#FFA3A3",
    red: "#EF2929",
    yellow: "#FCE94F",
    "red.2": "#CE0000",
    "red.hover": "#FF7F7F",
    "red.hover.2": "#432323",
  },
  secondary: {
    gray: "#AAAAAA",
    yellow: "#C4A000",
    orange: "#D36600",
    light: {
      blue: "#729FCF",
      "blue.2": "#3171FF",
    },
  },
  brand: {
    primary: "#F2F2F2",
    supper: {
      light: "#282828",
    },
    light: {
      shade: "#E0E0E0",
    },
  },
  neutral: {
    surface: "#060606",
    surface_light_theme: "#F5F5F5",
    on: {
      surface: {
        1: "#1A1A1A",
      },
    },
    element: {
      primary: "#FFFFFF",
      secondary: "#C2C2C2",
      secondary_light_theme: "#525252",
      tertiary: "#A3A3A3",
      tertiary_light_theme: "#7A7A7A",
      tertiary2: "#969696",
    },
    text: {
      button: "#060606",
    },
    stroke: {
      bold: "#757575",
      bold_light_theme: "#C2C2C2",
      light: "#282828",
      light_theme: "#EDEDED",
    },
    2: "#1A1A1A",
  },
  decorative: {
    "02": "#1F3348",
    "04": "#3B315B",
    "07": "#F0DD96",
  },
  accent: {
    link: "#0065D1",
    success: "#28CD41",
    warning: "#FF9500",
    info: "#0065D1",
    error: "#E8271C",
  },
  // primary: {
  //   black: "#20242D",
  //   font: "#0D1A26",
  // },
};

export default colors;
