import { Box, Flex, Image } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { Header } from "@/ui/Components/Header/Header";

import dynamic from "next/dynamic";
const Footer = dynamic(() => import("@/ui/Components/Footer"), { ssr: false });

export const RegularPageLayout: FC<{ children: ReactNode }> = ({
  children,
}) => (
  <Box minHeight="100vh" position="relative" overflow="hidden">
    <Flex flexDir="column" zIndex={2} position="relative" minHeight="100vh" >
      <Header />
      <Flex flexDirection="column" width="full" as="main" flex={1}>
        {children}
      </Flex>
      <Footer />
    </Flex>
    <Image
      src="/images/bombff.png"
      position="absolute"
      w="400px"
      bottom="0"
      zIndex={1}
      objectFit="cover"
      display={{ base: "none", lg: "block" }}
    />
  </Box>
);
