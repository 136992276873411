import { createContext, FC, ReactNode, useContext, useMemo } from "react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  WalletConnectWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

type SolanaWeb3ProviderProps = {};

export const SolanaWeb3Context = createContext<SolanaWeb3ProviderProps>({});

export const SolanaWeb3Provider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const network = clusterApiUrl(WalletAdapterNetwork.Mainnet);
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      new WalletConnectWalletAdapter({
        network: WalletAdapterNetwork.Mainnet,
        options: {
          projectId: "3ab5de1807b141cc1972fe8125ba600f",
        },
      }),
    ],
    []
  );

  return (
    <SolanaWeb3Context.Provider value={{}}>
      <ConnectionProvider endpoint="https://misty-still-dinghy.solana-mainnet.quiknode.pro/c8b395132a92ffea0d68af69bf206a24585de00a/">
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>{children}</WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </SolanaWeb3Context.Provider>
  );
};

export const useSolanaWeb3 = () => {
  const context = useContext(SolanaWeb3Context);
  if (!context) {
    throw new Error("useSolanaWeb3 must be used within a SolanaWeb3Provider");
  }

  return context;
};
